/** @jsxImportSource @emotion/react */
import { useParams } from 'react-router-dom'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

import { OptInInfo } from '../../types/opt-in'
import { useUpdateOptIn } from '../../utils/opt-in'

const MessageText = tw.p`text-sm`

export function OptInNotInitialized() {
  const { t } = useTranslation('translation')

  return <MessageText>{t('opt-in.not_initialized')}</MessageText>
}

export function OptInPending({ optInInfo }: { optInInfo: OptInInfo }) {
  const { optInId } = useParams()
  const { mutate } = useUpdateOptIn()
  const { t } = useTranslation('translation')

  const last4 = optInInfo.clientE164.slice(-4)
  const subscribedWith = optInInfo.user?.firstName
    ? optInInfo.user.firstName
    : optInInfo.group?.name
    ? optInInfo.group.name
    : 'this store'

  function handleOptInAccept() {
    mutate({ optInId, optIn: true })
  }

  return (
    <>
      <p tw="font-bold text-xl text-gray-900 text-center">
        {t('opt-in.pending.part1')} {subscribedWith}
      </p>
      <p tw="font-bold text-xl text-gray-900 text-center">XXX-XXX-{last4}</p>
      <MessageText>
        {t('opt-in.pending.part2')}{' '}
        <strong tw="font-bold">{optInInfo.group?.name}</strong>{' '}
        {t('opt-in.pending.part3')}
      </MessageText>
      <MessageText>{t('opt-in.pending.part4')}</MessageText>
      <MessageText>{t('opt-in.pending.part5')}</MessageText>
      <button
        tw="block w-full py-2 px-3 text-white text-center bg-base"
        onClick={handleOptInAccept}
      >
        {t('buttons.accept')}
      </button>
    </>
  )
}

export function OptInOptedOut({
  stylist,
}: {
  stylist?: { firstName: string; lastName: string }
}) {
  const { t } = useTranslation('translation')
  const subscribedWith = stylist?.firstName ? stylist.firstName : 'this store'

  return (
    <>
      <MessageText>
        {t('opt-in.opted_out.part1')} <strong>{subscribedWith}</strong>.
      </MessageText>
      <MessageText>{t('opt-in.opted_out.part2')}</MessageText>
    </>
  )
}

export function OptInOptedIn({
  stylist,
}: {
  stylist?: { firstName: string; lastName: string }
}) {
  const { t } = useTranslation('translation')
  const subscribedWith = stylist?.firstName ? stylist.firstName : 'this store'

  return (
    <>
      <MessageText>
        {t('opt-in.opted_in.part1')} <strong>{subscribedWith}</strong>.
      </MessageText>
      <MessageText>{t('opt-in.opted_in.part2')}</MessageText>
      <MessageText>{t('opt-in.opted_in.part3')}</MessageText>
    </>
  )
}

export function OptInError() {
  const { t } = useTranslation('translation')

  return (
    <>
      <MessageText>{t('opt-in.error.part1')}</MessageText>
      <MessageText>{t('opt-in.error.part2')}</MessageText>
    </>
  )
}
