import * as React from 'react'
import ReactDOM from 'react-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import { GlobalStyles } from 'twin.macro'

import './index.css'
import './i18n'
import App from './App'
import { AppProviders } from './context'

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <GlobalStyles />
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root')
)
