import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import produce from 'immer'

import { OptInInfo, OptInStatus } from '../types/opt-in'
import { client } from './client'
import { queryKeys } from './react-query/keys'
import { useSearchParams } from 'react-router-dom'

const queryKeyFactory = {
  makeOptInKey: (id: string) => [queryKeys.optIn, id],
}

export const optInKeys = {
  // ✅ all keys are arrays with exactly one object
  all: [{ scope: 'optIn' }] as const,
  states: () => [{ ...optInKeys.all[0], entity: 'state' }] as const,
  state: (clientId: string | null, userId: string | null) =>
    [{ ...optInKeys.states()[0], userId, clientId }] as const,
}

type OptInStatusResult = {
  result: { optIn: OptInInfo }
}

type ClientOptInStatus = {
  optIn: OptInInfo
}

type ClientOptInState = {
  optInRequired: boolean
  optInId: string | null
  optInState: OptInStatus
}

async function getOptInStatus(id: string) {
  const data: OptInStatusResult = await client
    .post('functions/getOptIn', { json: { objectId: id } })
    .json()

  return data.result
}

async function fetchClientOptInState({
  queryKey: [{ clientId, userId }],
}: QueryFunctionContext<ReturnType<typeof optInKeys['state']>>) {
  const data: { result: ClientOptInState } = await client
    .post('functions/getClientOptInState', { json: { clientId, userId } })
    .json()

  return data.result
}

function useClientOptInState() {
  const [params] = useSearchParams()
  const userId = params.get('stylist')
  const clientId = params.get('client')

  return useQuery({
    queryKey: optInKeys.state(clientId, userId),
    queryFn: fetchClientOptInState,
    enabled: Boolean(userId && clientId),
    retry: 1,
  })
}

function useOptInStatus(optInId: string) {
  return useQuery({
    queryKey: queryKeyFactory.makeOptInKey(optInId),
    queryFn: () => getOptInStatus(optInId),
    retry: 1,
  })
}

function useUpdateOptIn() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ optIn, optInId }) => {
      const optedIn = optIn ? { optedIn: true } : { optedOut: true }

      return client
        .post('functions/updateOptIn', { json: { optedIn, objectId: optInId } })
        .json()
    },
    {
      onMutate: async (variables: { optInId: string; optIn: boolean }) => {
        const { optInId, optIn } = variables
        const optInKey = queryKeyFactory.makeOptInKey(optInId)
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(optInKey)

        // Snapshot the previous value
        const previousOptIn =
          queryClient.getQueryData<ClientOptInStatus>(optInKey)

        // Optimistically update to the new value
        if (previousOptIn) {
          queryClient.setQueryData<ClientOptInStatus>(optInKey, (old) => {
            if (old) {
              return produce(old, (draft) => {
                draft.optIn.optInState = optIn ? 'OPTED_IN' : 'OPTED_OUT'
              })
            }
            return previousOptIn
          })
        }

        return { previousOptIn }
      },
      onError: (_error, variables, context) => {
        if (context?.previousOptIn) {
          queryClient.setQueryData(
            queryKeyFactory.makeOptInKey(variables.optInId),
            context.previousOptIn
          )
        }
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries(
          queryKeyFactory.makeOptInKey(variables.optInId)
        )
        queryClient.invalidateQueries(optInKeys.all)
      },
    }
  )
}

function useUnsubscibeClient() {
  return useMutation(
    async ({ clientId, email }: { clientId: string; email: string }) => {
      return client
        .post('functions/unsubscribeClient', { json: { clientId, email } })
        .json<{ result: { unsubscribedAt: string } }>()
    }
  )
}

export {
  useOptInStatus,
  useUpdateOptIn,
  useClientOptInState,
  useUnsubscibeClient,
}
