/** @jsxImportSource @emotion/react */
import { Link, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useLook } from '../utils/looks'
import * as colors from '../styles/colors'
import { FullPageSpinner } from '../components/lib'
import ProductGrid from '../components/ProductGrid'
import ProductItem from '../components/ProductItem'
import LookItem from '../components/LookItem'
import { productClick } from '../utils/productClick'
import { Layout } from '../components/layout'

function Look() {
  const location = useLocation()
  const { cid, lid } = useParams()
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const backRef = location.search.includes('looks=')
    ? `/collection${location.search}`
    : cid
    ? `/collection/${cid}${location.search}`
    : null

  const lookInfo = useLook(lid)

  if (lookInfo.data) {
    return (
      <Layout>
        {backRef && (
          <Link
            css={{
              display: 'inline-block',
              padding: '8px',
              color: colors.white,
              textTransform: 'uppercase',
              textDecoration: 'none',
              backgroundColor: colors.base,
            }}
            to={backRef}
          >
            {t('buttons.back.looks')}
          </Link>
        )}
        {Boolean(lookInfo.data.products.length) ? (
          <ProductGrid>
            {lookInfo.data.products.map((product) => (
              <div key={product.objectId}>
                <Link
                  css={{ color: colors.base, textDecoration: 'none' }}
                  to={`product/${product.objectId}${location.search}`}
                >
                  <ProductItem product={product} />
                </Link>
                {product.storeUrl && (
                  <a
                    href={product.storeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    css={{
                      display: 'block',
                      width: '100%',
                      marginTop: '.5rem',
                      padding: '.5rem .75rem',
                      color: colors.white,
                      backgroundColor: colors.base,
                      textDecoration: 'none',
                      textAlign: 'center',
                    }}
                    onClick={() => {
                      productClick(product.objectId)
                    }}
                  >
                    {t('buttons.view.website')}
                  </a>
                )}
              </div>
            ))}
          </ProductGrid>
        ) : (
          <div css={{ display: 'flex', justifyContent: 'center' }}>
            <LookItem look={lookInfo.data} />
          </div>
        )}
      </Layout>
    )
  }

  return <FullPageSpinner />
}

export default Look
