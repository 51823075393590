// useTypedQueries -
// workaround to type useQueries until stronger typing available by default

import { useQueries, UseQueryOptions, UseQueryResult } from 'react-query'

// https://github.com/tannerlinsley/react-query/issues/1675
type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T

function useTypedQueries<TQueries extends readonly UseQueryOptions[]>(
  queries: [...TQueries]
): {
  [ArrayElement in keyof TQueries]: UseQueryResult<
    TQueries[ArrayElement] extends { select: infer TSelect }
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        TSelect extends (data: any) => any
        ? ReturnType<TSelect>
        : never
      : Awaited<
          ReturnType<
            NonNullable<
              Extract<TQueries[ArrayElement], UseQueryOptions>['queryFn']
            >
          >
        >
  >
} {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQueries(
    queries as UseQueryOptions<unknown, unknown, unknown>[]
  ) as any
}

export default useTypedQueries
