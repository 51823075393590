/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import 'twin.macro'
import { useTranslation } from 'react-i18next'

import { useClientOptInState, useUpdateOptIn } from '../../utils/opt-in'

type OptInFormProps = { onCancel: () => void; onSubmit: () => void }

function OptInForm({ onCancel, onSubmit }: OptInFormProps) {
  const clientOptInState = useClientOptInState()
  const updateOptIn = useUpdateOptIn()

  const { t } = useTranslation('translation')

  function handleAccept() {
    if (clientOptInState.data?.optInId) {
      updateOptIn.mutate({
        optIn: true,
        optInId: clientOptInState.data.optInId,
      })
    }
  }

  return (
    <div tw="flex flex-row-reverse items-center mt-4">
      <button
        disabled={
          updateOptIn.isLoading ||
          clientOptInState.isLoading ||
          !clientOptInState.data
        }
        tw="block w-full ml-4 py-2 px-3 text-white text-center bg-base"
        onClick={handleAccept}
      >
        {t('buttons.accept')}
      </button>
      <button
        disabled={updateOptIn.isLoading}
        type="button"
        tw="block w-full py-2 px-3 text-base text-center border border-base"
        onClick={onCancel}
      >
        No Thanks
      </button>
    </div>
  )
}

export default OptInForm
