import { useQuery } from 'react-query'

import { client } from './client'
import { ProductDetail } from './products'
import useTypedQueries from './react-query/useTypedQueries'

export interface Look {
  objectId: string
  products: ProductDetail[]
  coverPhoto?: {
    objectId: string
    originalSrc?: string
  }
}

interface LookData {
  result: {
    look: Look
  }
}

async function getLook(id: string) {
  const data: LookData = await client
    .post(`functions/look/`, {
      json: {
        objectId: id,
      },
    })
    .json()

  return data?.result.look
}

function useLook(id: string) {
  return useQuery<Look>(['looks', id], () => getLook(id))
}

function useLooksList(list: string[] | null = []) {
  const queries =
    list?.map((lookId) => ({
      queryKey: ['products', lookId],
      queryFn: () => getLook(lookId),
    })) ?? []

  return useTypedQueries(queries)
}

export { useLook, useLooksList }
