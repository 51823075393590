/** @jsxImportSource @emotion/react */
import * as React from 'react'

function LookList({ children }: { children: React.ReactNode }) {
  return (
    <section
      css={{
        width: '250px',
        margin: '0 auto',
        '& > * + *': {
          marginTop: '2.5rem',
        },
      }}
    >
      {children}
    </section>
  )
}

export default LookList
