/** @jsxImportSource @emotion/react */

import placeholder from '../resources/no-image-product.png'
import { Look } from '../utils/looks'

interface LookProps {
  look: Look
}

function LookItem({ look }: LookProps) {
  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        width: '250px',
        height: '310px',
        marginTop: '.25rem',
      }}
    >
      {look.coverPhoto ? (
        <img
          css={{ width: '100%', objectFit: 'contain' }}
          src={look.coverPhoto?.originalSrc}
          alt=""
        />
      ) : (
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr 1fr',
            gridGap: '4px',
            height: '100%',
          }}
        >
          {look.products.slice(0, 4).map((product) => (
            <div
              css={[
                {
                  backgroundImage: `url(${
                    product.featuredImage || placeholder
                  })`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  boxShadow:
                    '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
                },
              ]}
              key={product.objectId}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default LookItem
