/** @jsxImportSource @emotion/react */
import 'twin.macro'

import avatar from '../resources/no-image-avatar.png'

function getFullName(stylist: { firstName: string; lastName: string }) {
  return `${stylist?.firstName || ''} ${stylist?.lastName || ''}`
}

function Header({
  stylist,
  logo,
}: {
  stylist?: { firstName: string; lastName: string; photo: string | null }
  logo?: string | null
}) {
  return (
    <header tw="flex flex-col items-center">
      <div tw="flex justify-center items-center w-full py-6 border-b border-gray-500 bg-white">
        <div tw="h-8">
          <img
            tw="h-full object-contain"
            src={logo ?? '/images/no-logo-oneshop.png'}
            alt=""
          />
        </div>
      </div>
      <div tw="flex flex-col items-center">
        <div tw="flex items-center justify-center -mt-4 ">
          <div>
            <img
              tw="w-16 h-16 rounded-full object-cover"
              src={stylist?.photo || avatar}
              alt=""
            />
          </div>
        </div>
        {stylist ? (
          <div tw="font-bold text-gray-900">{getFullName(stylist)}</div>
        ) : null}
      </div>
    </header>
  )
}

export default Header
