/** @jsxImportSource @emotion/react */
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation } from 'swiper'
import { css } from '@emotion/react'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'

import type { Product } from '../utils/products'
import placeholder from '../resources/no-image-product.png'
import * as colors from '../styles/colors'
import { formatCurrency } from '../utils/number-helpers'
import { productClick } from '../utils/productClick'
import { useTranslation } from 'react-i18next'
import { useStylist } from '../utils/useStylist'
import { FullPageSpinner } from './lib'

SwiperCore.use([Pagination, Navigation])

const baseNavStyles = css({
  position: 'absolute',
  top: '50%',
  display: 'block',
  width: '2rem',
  height: '2rem',
  color: colors.base,
  backgroundColor: colors.white,
  borderRadius: '9999px',
  zIndex: 10,
  cursor: 'pointer',
  '&.swiper-button-disabled': {
    opacity: 0.35,
    cursor: 'auto',
    pointerEvents: 'none',
  },
  '&.swiper-button-lock': { display: 'none' },
})
const prevNavStyles = css({ left: '10px', right: 'auto' })
const nextNavStyles = css({ left: 'auto', right: '10px' })

type ProductDetailProps = {
  item: Product
}

function ProductDetail({ item }: ProductDetailProps) {
  const stylistInfo = useStylist()

  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  if (stylistInfo.data) {
    return (
      <div css={{ margin: '16px auto 0', maxWidth: 350 }}>
        <Swiper
          id="productSlides"
          css={{
            width: '100%',
            maxWidth: '350px',
            height: '450px',
            '& .swiper-slide': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
            '& .swiper-button-disabled': {
              opacity: 0,
              cursor: 'auto',
              pointerEvents: 'none',
            },
          }}
          spaceBetween={0}
          slidesPerView={1}
          pagination
          navigation={{ nextEl: '.next', prevEl: '.prev' }}
          watchOverflow={true}
        >
          {item.images.length ? (
            item.images.map((image: any) => (
              <SwiperSlide key={image.objectId}>
                <img
                  src={image.originalSrc}
                  css={{ objectFit: 'contain' }}
                  alt=""
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img src={placeholder} css={{ objectFit: 'contain' }} alt="" />
            </SwiperSlide>
          )}
          <div className="prev" css={[baseNavStyles, prevNavStyles]}>
            <svg
              css={{ width: '2rem', height: '2rem' }}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
          <div className="next" css={[baseNavStyles, nextNavStyles]}>
            <svg
              css={{ width: '2rem', height: '2rem' }}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </Swiper>
        <div>
          <h1 css={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            {item.product.brand}
          </h1>
          <p>{item.product.title}</p>
          {item.product.price ? (
            <p css={{ marginTop: '.25rem' }}>
              {formatCurrency(+item.product.price)}{' '}
              {stylistInfo.data.group?.name.toLowerCase().includes('unode50')
                ? 'USD'
                : ''}
            </p>
          ) : null}
          {item.product.storeUrl && (
            <a
              href={item.product.storeUrl}
              target="_blank"
              rel="noopener noreferrer"
              css={{
                display: 'block',
                width: '100%',
                marginTop: '.5rem',
                padding: '.5rem .75rem',
                color: colors.white,
                backgroundColor: colors.base,
                textDecoration: 'none',
                textAlign: 'center',
              }}
              onClick={() => {
                productClick(item.product.objectId)
              }}
            >
              {t('buttons.view.website')}
            </a>
          )}
          <p
            css={{
              marginTop: '.75rem',
              fontSize: '.85rem',
              whiteSpace: 'pre-line',
            }}
          >
            {item.product.description}
          </p>
        </div>
      </div>
    )
  }

  return <FullPageSpinner />
}

export default ProductDetail
