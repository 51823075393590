/** @jsxImportSource @emotion/react */
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'

import { useProductsList } from '../utils/products'
import * as colors from '../styles/colors'
import { FullPageSpinner } from '../components/lib'
import ProductGrid from '../components/ProductGrid'
import ProductItem from '../components/ProductItem'
import { productClick } from '../utils/productClick'
import { Layout } from '../components/layout'

function useProductsQueryString(query: string) {
  const values = queryString.parse(query, { arrayFormat: 'comma' })

  if (typeof values.products === 'string') {
    return [values.products]
  }

  return values.products
}

function Products() {
  const location = useLocation()
  const queryValues = useProductsQueryString(location.search)
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const productQueries = useProductsList(queryValues)

  if (productQueries.some((d) => d.isLoading)) {
    return <FullPageSpinner />
  }

  if (productQueries.length < 1) {
    return <p>There are no products</p>
  }

  return (
    <Layout>
      <ProductGrid>
        {productQueries.map((queryResult) => {
          const product = queryResult.data?.product

          return (
            product && (
              <div key={product.objectId}>
                <Link
                  css={{ color: colors.base, textDecoration: 'none' }}
                  to={`/product/${product?.objectId}${location.search}`}
                >
                  {product && <ProductItem product={product} />}
                </Link>
                {product.storeUrl && (
                  <a
                    href={product.storeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    css={{
                      display: 'block',
                      width: '100%',
                      marginTop: '.5rem',
                      padding: '.5rem .75rem',
                      color: colors.white,
                      backgroundColor: colors.base,
                      textDecoration: 'none',
                      textAlign: 'center',
                    }}
                    onClick={() => {
                      productClick(product.objectId)
                    }}
                  >
                    {t('buttons.view.website')}
                  </a>
                )}
              </div>
            )
          )
        })}
      </ProductGrid>
    </Layout>
  )
}

export default Products
