/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import { useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useTranslation } from 'react-i18next'

import {
  useClientSignUpPhoneExists,
  useCreateClientSignUpPhone,
} from '../utils/use-client'
import { useStylist, useStylistQueryString } from '../utils/useStylist'
import { Layout } from '../components/layout'
import { FullPageSpinner } from '../components/lib'
import { OptInOptedIn } from '../components/opt-in/opt-in-messages'

type FormState = {
  phoneNumber: string
}

const inputStyles =
  'block w-full py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 shadow-sm border border-gray-300 rounded-md sm:text-sm'
const Col = tw.div`col-span-6 `
const Label = tw.label`block text-sm font-medium text-gray-700`
const MessageText = tw.p`text-sm`

function ClientSignUp() {
  const params = useParams()
  const { t } = useTranslation('translation')
  const stylist = useStylist()
  const stylistId = useStylistQueryString()
  const clientId = params.clientId
  const canSignUp = useClientSignUpPhoneExists(clientId)
  const createSignUp = useCreateClientSignUpPhone()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormState>({
    defaultValues: { phoneNumber: '' },
  })

  function handleFormSubmit(data: FormState) {
    if (!stylistId) return

    createSignUp.mutate({
      clientId: params.clientId,
      stylistId,
      e164: data.phoneNumber,
    })
  }

  if (!stylist.data && !stylist.isLoading) {
    return <p>Something went wrong.</p>
  }

  if (canSignUp.data && stylist.data) {
    return (
      <Layout>
        {canSignUp.data.signUpPhoneExists ? (
          <OptInOptedIn stylist={stylist.data.user} />
        ) : (
          <>
            <p tw="font-bold text-xl text-gray-900 text-center">
              {t('opt-in.pending.part1')} {stylist.data?.user.firstName}
            </p>

            <div tw="mt-4 shadow overflow-hidden sm:rounded-md">
              <div tw="px-4 py-5 bg-white sm:p-6">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <div tw="grid grid-cols-6 gap-6">
                    <Col>
                      <Label htmlFor="phoneNumber">Phone Number</Label>
                      <Controller
                        control={control}
                        name="phoneNumber"
                        rules={{
                          required: 'This field is required.',
                          validate: {
                            validPhoneNumber: (value) =>
                              isPossiblePhoneNumber(value)
                                ? true
                                : 'You must enter a valid phone number.',
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <PhoneInput
                            id="phoneNumber"
                            css={{
                              '& input': tw`${inputStyles}`,
                            }}
                            defaultCountry="US"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="phoneNumber"
                        render={({ message }) => (
                          <p tw="mt-2 text-red-600">{message}</p>
                        )}
                      />
                    </Col>

                    <div tw="col-span-6 mx-auto">
                      <button
                        type="submit"
                        tw="block w-full py-2 px-3 text-white text-center bg-base"
                      >
                        {t('buttons.accept')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div tw="mt-6">
              <MessageText>
                {t('opt-in.pending.part2')}{' '}
                <strong tw="font-bold">
                  {stylist.data?.group?.name ?? 'this store'}
                </strong>{' '}
                {t('opt-in.pending.part3')}
              </MessageText>
              <MessageText>{t('opt-in.pending.part4')}</MessageText>
              <MessageText>{t('opt-in.pending.part5')}</MessageText>
            </div>
          </>
        )}
      </Layout>
    )
  }

  return <FullPageSpinner />
}

export default ClientSignUp
