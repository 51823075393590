/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useParams } from 'react-router-dom'

import { useOptInStatus } from '../utils/opt-in'
import Header from '../components/header'
import { FullPageSpinner } from '../components/lib'
import {
  OptInNotInitialized,
  OptInPending,
  OptInOptedIn,
  OptInOptedOut,
  OptInError,
} from '../components/opt-in/opt-in-messages'

function OptIn() {
  const { optInId } = useParams()
  const optInInfo = useOptInStatus(optInId)

  if (optInInfo.data)
    return (
      <>
        <Header
          stylist={optInInfo.data.optIn.user}
          logo={optInInfo.data.optIn.group?.logo}
        />

        <main tw="max-w-lg mx-auto mt-6 px-5 pb-8">
          <div tw="mt-10 text-gray-700 space-y-4">
            {optInInfo.data.optIn.optInState === 'PENDING' ? (
              <OptInPending optInInfo={optInInfo.data.optIn} />
            ) : null}

            {optInInfo.data.optIn.optInState === 'NOT_INITIALIZED' ? (
              <OptInNotInitialized />
            ) : null}

            {optInInfo.data.optIn.optInState === 'OPTED_IN' ? (
              <OptInOptedIn stylist={optInInfo.data.optIn.user} />
            ) : null}

            {optInInfo.data.optIn.optInState === 'OPTED_OUT' ? (
              <OptInOptedOut stylist={optInInfo.data.optIn.user} />
            ) : null}
            <div tw="flex justify-center mt-4">
              <img
                src="/images/powered-by-one-shop.png"
                tw="w-2/5 h-full object-contain"
                alt=""
              />
            </div>
          </div>
        </main>
      </>
    )

  if (optInInfo.isError) {
    return (
      <>
        <Header />
        <main tw="max-w-lg mx-auto mt-6 px-5 pb-8">
          <div tw="mt-10 text-gray-700 space-y-4">
            <OptInError />
            <div tw="flex justify-center mt-4">
              <img
                src="/images/powered-by-one-shop.png"
                tw="w-2/5 h-full object-contain"
                alt=""
              />
            </div>
          </div>
        </main>
      </>
    )
  }

  return <FullPageSpinner />
}

export default OptIn
