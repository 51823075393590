import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'

import { client } from './client'

interface Stylist {
  group: {
    name: string
    logo: string | null
  } | null
  user: {
    firstName: string
    lastName: string
    photo: string | null
  }
}

async function getStylist(id: string) {
  if (!id) return null

  const data: { result: Stylist } = await client
    .post(`functions/getUser/`, {
      json: {
        objectId: id,
      },
    })
    .json()

  return data.result
}

function useStylistQueryString() {
  const location = useLocation()
  const values = queryString.parse(location.search)

  if (typeof values.stylist !== 'string') return null
  return values.stylist
}

function useStylist() {
  const params = useParams()
  const id = useStylistQueryString()

  const stylistId = id ?? params.stylistId
  return useQuery(['stylist', stylistId], () => getStylist(stylistId), {
    retry: false,
    staleTime: Infinity,
  })
}

export { useStylist, useStylistQueryString }
