/** @jsxImportSource @emotion/react */
import * as React from 'react'
import tw from 'twin.macro'

import { useStylist } from '../utils/useStylist'
import Header from './header'
import { FullPageSpinner } from './lib'

const Main = tw.main`max-w-lg mx-auto mt-6 px-5 pb-8`

function Layout({ children }: { children: React.ReactNode }) {
  const stylistInfo = useStylist()

  if (stylistInfo.data) {
    return (
      <React.Fragment>
        <Header
          stylist={stylistInfo.data.user}
          logo={stylistInfo.data.group?.logo}
        />

        <Main tw="max-w-lg mx-auto mt-6 px-5 pb-8">{children}</Main>
      </React.Fragment>
    )
  }

  return <FullPageSpinner />
}

export { Layout }
