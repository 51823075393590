import { client } from './client'

async function productClick(id: string) {
  client.post('functions/productClick', {
    json: {
      objectId: id,
    },
  })
}

export { productClick }
