/** @jsxImportSource @emotion/react */
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { useLooksList } from '../utils/looks'
import * as colors from '../styles/colors'
import { FullPageSpinner } from '../components/lib'
import LookList from '../components/LookList'
import LookItem from '../components/LookItem'
import { Layout } from '../components/layout'

function useLooksQueryString(query: string) {
  const values = queryString.parse(query, { arrayFormat: 'comma' })

  if (typeof values.looks === 'string') {
    return [values.looks]
  }

  return values.looks
}

function Looks() {
  const location = useLocation()
  const queryValues = useLooksQueryString(location.search)

  const lookQueries = useLooksList(queryValues)

  if (lookQueries.some((d) => d.isLoading)) {
    return <FullPageSpinner />
  }

  return (
    <Layout>
      <LookList>
        {lookQueries.map((queryResult, idx) => (
          <div
            key={queryResult.data?.objectId}
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h2
              css={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 1 }}
            >{`LOOK ${idx + 1}`}</h2>
            <Link to={`/look/${queryResult.data?.objectId}${location.search}`}>
              {queryResult.data && <LookItem look={queryResult.data} />}
            </Link>
            {queryResult.data?.products &&
              queryResult.data.products.length > 0 && (
                <Link
                  to={`/look/${queryResult.data?.objectId}?${location.search}`}
                  css={{
                    width: '100%',
                    marginTop: '.75rem',
                    padding: '.25rem .5rem',
                    color: colors.white,
                    textAlign: 'center',
                    textDecoration: 'none',
                    textTransform: 'uppercase',
                    backgroundColor: colors.base,
                  }}
                >
                  View Products
                </Link>
              )}
          </div>
        ))}
      </LookList>
    </Layout>
  )
}

export default Looks
