/** @jsxImportSource @emotion/react */
import { ProductDetail } from '../utils/products'
import placeholder from '../resources/no-image-product.png'
import { formatCurrency } from '../utils/number-helpers'
import { useStylist } from '../utils/useStylist'
import { FullPageSpinner } from './lib'

interface ProductProps {
  product: ProductDetail
}

function ProductItem({ product }: ProductProps) {
  const stylistInfo = useStylist()

  if (stylistInfo.data) {
    return (
      <div>
        <img
          css={{ width: '100%', height: '200px', objectFit: 'contain' }}
          src={product.featuredImage || placeholder}
          alt=""
        />
        <div css={{ padding: '.75rem 0' }}>
          <h2
            css={{
              fontWeight: 'bold',
              lineHeight: 1,
              textTransform: 'uppercase',
            }}
          >
            {product.brand}
          </h2>
          <p css={{ marginTop: '.5rem', fontSize: '.85rem' }}>
            {product.title}
          </p>
          {product.price ? (
            <p
              css={{
                marginTop: '.5rem',
                fontSize: '.85rem',
                lineHeight: 1,
              }}
            >
              {formatCurrency(+product.price)}{' '}
              {stylistInfo.data.group?.name.toLowerCase().includes('unode50')
                ? 'USD'
                : ''}
            </p>
          ) : null}
        </div>
      </div>
    )
  }

  return <FullPageSpinner />
}

export default ProductItem
