import { useQuery } from 'react-query'

import { client } from './client'
import useTypedQueries from './react-query/useTypedQueries'

export interface ProductDetail {
  objectId: string
  storeUrl: string | null
  brand?: string | null
  description?: string | null
  featuredImage?: string | null
  price?: string | null
  title?: string | null
}

interface ProductImage {
  objectId: string
  originalSrc?: string
}

interface ProductData {
  result: {
    images: ProductImage[]
    product: ProductDetail
  }
}

export type Product = {
  images: ProductImage[]
  product: ProductDetail
}

async function getProduct(id: string) {
  const data: ProductData = await client
    .post(`functions/product/`, {
      json: {
        objectId: id,
      },
    })
    .json()

  return data?.result
}

function useProduct(id: string) {
  return useQuery<Product>(['products', id], () => getProduct(id))
}

function useProductsList(list: string[] | null = []) {
  const queries =
    list?.map((productId) => ({
      queryKey: ['products', productId],
      queryFn: () => getProduct(productId),
    })) ?? []

  return useTypedQueries(queries)
}

export { useProduct, useProductsList }
