import { useQuery } from 'react-query'
import { client } from './client'

import { UserPointer } from '../lib/types'
import { Look } from './looks'

export interface Collection {
  className: 'Collection'
  createdAt: string
  looks: Look[]
  objectId: string
  updatedAt: string
  user: UserPointer
  __type: 'Object'
}

interface CollectionData {
  result: Collection
}

async function getCollection(id: string) {
  const data: CollectionData = await client
    .post(`functions/collection/`, {
      json: {
        objectId: id,
      },
    })
    .json()

  return data.result
}

function useCollection(id: string) {
  return useQuery<Collection>(['collections', id], () => getCollection(id))
}

export { useCollection }
