import { useMutation, useQuery, useQueryClient } from 'react-query'
import produce from 'immer'

import { client } from './client'
import { optInKeys } from './opt-in'

type Result = {
  result: ClientSignUpInfo
}

type ClientSignUpInfo = {
  objectId: string
  signUpPhoneExists: boolean
}

type CreateSignUpPhoneProps = {
  stylistId: string
  clientId: string
  e164: string
}

const clientInfoKeys = {
  all: ['clients'] as const,
  details: () => [...clientInfoKeys.all, 'detail'],
  detail: (id: string) => [...clientInfoKeys.details(), id] as const,
  signUpPhone: (id: string) =>
    [...clientInfoKeys.detail(id), 'signUpPhone'] as const,
}

async function getSignUpPhoneExists(id: string) {
  const data: Result = await client
    .post('functions/clientSignUpPhoneExists', {
      json: { objectId: id },
    })
    .json()

  return data.result
}

function useClientSignUpPhoneExists(clientId: string) {
  return useQuery(
    [...clientInfoKeys.signUpPhone(clientId)],
    () => getSignUpPhoneExists(clientId),
    {
      select: (data) => ({ signUpPhoneExists: data.signUpPhoneExists }),
      notifyOnChangeProps: ['data', 'error'],
    }
  )
}

function useCreateClientSignUpPhone() {
  const queryClient = useQueryClient()
  return useMutation(
    ({ clientId, stylistId, e164 }) => {
      return client
        .post('functions/addClientSignUpPhone', {
          json: { clientId, stylistId, e164 },
        })
        .json()
    },
    {
      onMutate: ({ clientId, stylistId, e164 }: CreateSignUpPhoneProps) => {
        const previousClientInfo = queryClient.getQueryData<ClientSignUpInfo>([
          ...clientInfoKeys.signUpPhone(clientId),
        ])

        if (previousClientInfo) {
          queryClient.setQueryData<ClientSignUpInfo>(
            [...clientInfoKeys.signUpPhone(clientId)],
            (old) => {
              if (old) {
                return produce(old, (draft) => {
                  draft.signUpPhoneExists = true
                })
              }
              return previousClientInfo
            }
          )
        }

        return { previousClientInfo }
      },
      onError: (_error, variables, context) => {
        if (context?.previousClientInfo) {
          queryClient.setQueryData(
            [...clientInfoKeys.signUpPhone(variables.clientId)],
            context.previousClientInfo
          )
        }
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries([
          ...clientInfoKeys.signUpPhone(variables.clientId),
        ])
        queryClient.invalidateQueries(optInKeys.all)
      },
    }
  )
}

export { useClientSignUpPhoneExists, useCreateClientSignUpPhone }
