/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { jsx } from '@emotion/react'
import tw from 'twin.macro'
import { useForm, Controller } from 'react-hook-form'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import { ErrorMessage } from '@hookform/error-message'
import { useTranslation } from 'react-i18next'

import { useCreateClientSignUpPhone } from '../../utils/use-client'

import 'react-phone-number-input/style.css'

type FormState = {
  phoneNumber: string
}

type SignUpFormProps = { onCancel: () => void; onSubmit: () => void }

const inputStyles =
  'block w-full py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 shadow-sm border border-gray-300 rounded-md sm:text-sm'
const Col = tw.div`col-span-6 `
const Label = tw.label`block text-sm font-medium text-gray-700`

function SignUpForm({ onCancel, onSubmit }: SignUpFormProps) {
  const [params] = useSearchParams()
  const stylistId = params.get('stylist')
  const clientId = params.get('client')

  const createSignUp = useCreateClientSignUpPhone()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormState>({
    defaultValues: { phoneNumber: '' },
  })
  const { t } = useTranslation('translation')

  function handleFormSubmit(data: FormState) {
    if (!clientId || !stylistId) {
      return
    }

    createSignUp.mutate({
      e164: data.phoneNumber,
      clientId,
      stylistId,
    })
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div tw="grid grid-cols-6 gap-6">
        <Col>
          <Label htmlFor="phoneNumber">Phone Number</Label>
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              required: 'This field is required.',
              validate: {
                validPhoneNumber: (value) =>
                  isPossiblePhoneNumber(value)
                    ? true
                    : 'You must enter a valid phone number.',
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <PhoneInput
                id="phoneNumber"
                css={{
                  '& input': tw`${inputStyles}`,
                }}
                defaultCountry="US"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="phoneNumber"
            render={({ message }) => <p tw="mt-2 text-red-600">{message}</p>}
          />
        </Col>

        <Col tw="flex justify-between items-center">
          <button
            disabled={createSignUp.isLoading}
            type="button"
            tw="block w-full py-2 px-3 text-base text-center border border-base"
            onClick={onCancel}
          >
            No Thanks
          </button>
          <button
            disabled={createSignUp.isLoading || !stylistId || !clientId}
            type="submit"
            tw="block w-full ml-4 py-2 px-3 text-white text-center bg-base"
          >
            {t('buttons.accept')}
          </button>
        </Col>
      </div>
    </form>
  )
}

export default SignUpForm
