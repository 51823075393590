/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import { css, Global } from '@emotion/react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useUnsubscibeClient } from '../utils/opt-in'

const globalStyles = css`
  html,
  body {
    ${tw`h-full`}
  }
  #root {
    ${tw`flex flex-col h-full`}
  }
  main {
    ${tw`flex-1`}
  }
`

function useUnsubscribeParams() {
  const [searchParams] = useSearchParams()

  const clientId = searchParams.get('clientId')
  const email = searchParams.get('email')

  return { clientId, email }
}

function Unsubscribe() {
  const { clientId, email } = useUnsubscribeParams()
  const unsubscribeMutation = useUnsubscibeClient()
  const navigate = useNavigate()

  function handleUnsubscribe() {
    if (!clientId || !email) {
      throw new Error(
        'Missing the clientId or email. You must access this page with the proper parameters.'
      )
    }

    unsubscribeMutation.mutate(
      { clientId, email },
      {
        onSuccess: () => {
          navigate('success', { replace: true })
        },
      }
    )
  }

  return (
    <>
      <Global styles={globalStyles} />

      <main tw="max-w-lg mx-auto mt-6 px-5 pb-8">
        {clientId && email ? (
          <>
            <h1 tw="text-2xl">Email Preferences</h1>
            <p>
              To unsubscribe <strong>{email}</strong> from this store's emails,
              please click the unsubscribe button below.
            </p>

            <div tw="mt-8">
              <button
                disabled={unsubscribeMutation.isLoading}
                tw="px-4 py-2 text-sm font-medium text-white uppercase bg-base rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                onClick={handleUnsubscribe}
              >
                Unsubscribe
              </button>
            </div>
          </>
        ) : (
          <p>
            Something went wrong, this page was accessed without the proper
            credentials. Please contact your sales associate / stylist to be
            removed from their mailing list.
          </p>
        )}
      </main>
      <footer tw="py-6">
        <div tw="flex justify-center mt-4">
          <img
            src="/images/powered-by-one-shop.png"
            tw="w-2/5 h-full object-contain"
            alt=""
          />
        </div>
      </footer>
    </>
  )
}

export default Unsubscribe
