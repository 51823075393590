/** @jsxImportSource @emotion/react */
import * as React from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import 'twin.macro'

import { useClientOptInState } from './utils/opt-in'
import NotFound from './NotFound'
import Collection from './pages/Collection'
import Look from './pages/Look'
import Product from './pages/Product'
import Products from './pages/Products'
import Looks from './pages/Looks'
import OptIn from './pages/opt-in'
import Unsubscribe from './pages/unsubscribe'
import UnsubscribeSuccess from './pages/unsubscribe-success'
import ClientSignUp from './pages/client-sign-up'
import FutureUpdates from './components/future-updates/future-updates'
import { FullPageSpinner } from './components/lib'

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<NotFound />} />

      <Route path="collection" element={<Looks />} />
      <Route path="collection/:cid" element={<Collection />} />
      <Route path="collection/:cid/look/:lid" element={<Look />} />
      <Route
        path="collection/:cid/look/:lid/product/:pid"
        element={<Product />}
      />

      <Route path="look" element={<Products />} />
      <Route path="look/:lid" element={<Look />} />
      <Route path="look/:lid/product/:pid" element={<Product />} />

      <Route path="product/:pid" element={<Product />} />

      <Route path="opt-in/:optInId" element={<OptIn />} />
      <Route path="client-sign-up/:clientId" element={<ClientSignUp />} />
      <Route path="opt-in/unsubscribe" element={<Unsubscribe />} />
      <Route
        path="opt-in/unsubscribe/success"
        element={<UnsubscribeSuccess />}
      />
    </Routes>
  )
}

function App() {
  const [params] = useSearchParams()
  const clientId = params.get('client')

  const optInStateQuery = useClientOptInState()

  const renderPopup =
    optInStateQuery.data?.optInRequired &&
    clientId &&
    (optInStateQuery.data?.optInState === 'PENDING' ||
      !optInStateQuery.data?.optInId)

  return (
    <React.Suspense
      fallback={<FullPageSpinner message="Loading language preferences..." />}
    >
      <AppRoutes />
      {renderPopup && <FutureUpdates />}
    </React.Suspense>
  )
}

export default App
