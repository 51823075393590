/** @jsxImportSource @emotion/react */
import * as React from 'react'

function ProductGrid({ children }: { children: React.ReactNode }) {
  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(115px, 1fr))',
        gridGap: '16px',
        justifyContent: 'center',
        marginTop: '16px',
      }}
    >
      {children}
    </div>
  )
}

export default ProductGrid
