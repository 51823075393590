import ky from 'ky'

const APPLICATION_ID = '15eaa65a6d14458698fffb72bec53dd1'

const config = {
  prefixUrl:
    process.env.REACT_APP_SERVER_URL || 'https://prod.oneshopretail.com/parse/',
  timeout: 30000,
}

const client = ky.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('X-Parse-Application-Id', APPLICATION_ID)
      },
    ],
  },
  ...config,
})

export { client }
