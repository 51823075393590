/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react'
import { ClassNames, jsx } from '@emotion/react'
import tw from 'twin.macro'
import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import { useClientOptInState } from '../../utils/opt-in'
import { useStylist } from '../../utils/useStylist'
import OptInForm from './opt-in-form'
import SignUpForm from './sign-up-form'

function FutureUpdates() {
  const [isOpen, setIsOpen] = React.useState(false)

  const stylistInfo = useStylist()
  const clientOptInState = useClientOptInState()

  const { t } = useTranslation('translation')

  // delay popup opening slightly
  React.useEffect(() => {
    setTimeout(() => setIsOpen(true), 500)
  }, [])

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <ClassNames>
      {({ css }) => (
        <React.Fragment>
          <Transition
            show={!isOpen}
            as={React.Fragment}
            enter={css(tw`duration-300 ease-out`)}
            enterFrom={css(tw`opacity-0`)}
            enterTo={css(tw`opacity-100`)}
            leave={css(tw`duration-300 ease-in`)}
            leaveFrom={css(tw`opacity-100`)}
            leaveTo={css(tw`opacity-0`)}
          >
            <button
              tw="fixed bottom-16 left-4 px-4 py-2 text-sm font-medium text-white uppercase bg-base rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              onClick={() => setIsOpen(true)}
            >
              Get Future Updates
            </button>
          </Transition>

          <Transition show={isOpen} as={React.Fragment}>
            <Dialog
              as="div"
              tw="fixed inset-0 z-10 overflow-y-auto"
              onClose={closeModal}
            >
              <div tw="min-h-screen px-4 text-center">
                <Transition.Child
                  as={React.Fragment}
                  enter={css(tw`duration-300 ease-out`)}
                  enterFrom={css(tw`opacity-0`)}
                  enterTo={css(tw`opacity-100`)}
                  leave={css(tw`duration-300 ease-in`)}
                  leaveFrom={css(tw`opacity-100`)}
                  leaveTo={css(tw`opacity-0`)}
                >
                  <Dialog.Overlay tw="fixed inset-0 bg-black bg-opacity-40" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  tw="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={React.Fragment}
                  enter={css(tw`duration-300 ease-out`)}
                  enterFrom={css(tw`!opacity-0`)}
                  enterTo={css(tw`!opacity-100`)}
                  leave={css(tw`duration-300 ease-in`)}
                  leaveFrom={css(tw`!opacity-100`)}
                  leaveTo={css(tw`!opacity-0`)}
                >
                  <div tw="inline-block p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                      as="h3"
                      tw="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t('opt-in.popup_header')}
                      {stylistInfo.data?.group?.name}
                    </Dialog.Title>

                    {!clientOptInState.data?.optInId ? (
                      <SignUpForm onCancel={closeModal} onSubmit={closeModal} />
                    ) : (
                      <OptInForm onCancel={closeModal} onSubmit={closeModal} />
                    )}

                    <div tw="mt-2">
                      <p tw="text-sm text-gray-500">
                        {t('opt-in.pending.part2')}{' '}
                        <strong tw="font-bold">
                          {stylistInfo.data?.group?.name}
                        </strong>{' '}
                        {t('opt-in.pending.part3')}
                      </p>
                      <p tw="text-sm text-gray-500">
                        {t('opt-in.pending.part5')} {t('opt-in.pending.part4')}
                      </p>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </React.Fragment>
      )}
    </ClassNames>
  )
}

export default FutureUpdates
