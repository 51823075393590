/** @jsxImportSource @emotion/react */
import { Link, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useProduct } from '../utils/products'
import * as colors from '../styles/colors'
import { FullPageSpinner } from '../components/lib'
import ProductDetail from '../components/ProductDetail'
import { Layout } from '../components/layout'

function Product() {
  const location = useLocation()
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { cid, lid, pid } = useParams()

  const backRef = location.search.includes('products=')
    ? `/look${location.search}`
    : cid && lid
    ? `/collection/${cid}/look/${lid}${location.search}`
    : lid
    ? `/look/${lid}${location.search}`
    : null

  const productInfo = useProduct(pid)

  if (productInfo.isLoading) {
    return <FullPageSpinner />
  }

  return (
    <Layout>
      {backRef && (
        <Link
          css={{
            display: 'inline-block',
            padding: '8px',
            color: colors.white,
            textTransform: 'uppercase',
            textDecoration: 'none',
            backgroundColor: colors.base,
          }}
          to={backRef}
        >
          {t('buttons.back.products')}
        </Link>
      )}
      <ProductDetail item={productInfo.data!} />
    </Layout>
  )
}

export default Product
