/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import { css, Global } from '@emotion/react'

const globalStyles = css`
  html,
  body {
    ${tw`h-full`}
  }
  #root {
    ${tw`flex flex-col h-full`}
  }
  main {
    ${tw`flex-1`}
  }
`
function UnsubscribeSuccess() {
  return (
    <>
      <Global styles={globalStyles} />

      <main tw="max-w-lg mx-auto mt-6 px-5 pb-8">
        <h1 tw="text-2xl">Unsubscribe Successful</h1>
        <p tw="mt-4">
          You will no longer receive email marketing from this store or its
          members.
        </p>
      </main>
      <footer tw="py-6">
        <div tw="flex justify-center mt-4">
          <img
            src="/images/powered-by-one-shop.png"
            tw="w-2/5 h-full object-contain"
            alt=""
          />
        </div>
      </footer>
    </>
  )
}

export default UnsubscribeSuccess
