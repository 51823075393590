/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { Spinner as EvilSpinner } from '@emotion-icons/evil'
import * as colors from '../styles/colors'

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

const Spinner = styled(EvilSpinner)({
  animation: `${spin} 1s steps(12) infinite`,
})
Spinner.defaultProps = {
  'aria-label': 'loading',
}

const LoadingWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  zIndex: 50,
})

const Backdrop = styled('div')({
  inset: 0,
  display: 'block',
  position: 'absolute',
  transform: 'translateZ(0px)',
  contain: 'strict',
  zIndex: 2,
})

const LoadingCard = styled('div')({
  padding: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'auto',
  maxWidth: '270px',
  zIndex: 10,
  background: colors.base,
  borderRadius: '8px',
  color: colors.white,
})

function FullPageSpinner({
  message = 'Your style awaits...',
}: {
  message?: string
}) {
  return (
    <LoadingWrapper>
      <Backdrop />
      <LoadingCard>
        <span
          css={{
            width: '50px',
            height: '50px',
            opacity: 0.75,
            margin: '0 auto',
            display: 'block',
            position: 'relative',
          }}
        >
          <Spinner />
        </span>
        <p>{message}</p>
      </LoadingCard>
    </LoadingWrapper>
  )
}

export { Spinner, FullPageSpinner }
