/** @jsxImportSource @emotion/react */
import { Link, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FullPageSpinner } from '../components/lib'
import LookList from '../components/LookList'
import LookItem from '../components/LookItem'
import * as colors from '../styles/colors'
import { useCollection } from '../utils/collections'
import { Layout } from '../components/layout'

function Collection() {
  const { cid } = useParams()
  const location = useLocation()
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const collectionInfo = useCollection(cid)

  if (collectionInfo.isLoading) {
    return <FullPageSpinner />
  }

  return (
    <Layout>
      <LookList>
        {collectionInfo.data?.looks.map((look, idx) => (
          <div
            key={look.objectId}
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h2
              css={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 1 }}
            >{`LOOK ${idx + 1}`}</h2>
            <Link to={`look/${look.objectId}${location.search}`}>
              <LookItem look={look} />
            </Link>
            {look.products && (
              <Link
                to={`look/${look.objectId}${location.search}`}
                css={{
                  width: '100%',
                  marginTop: '.75rem',
                  padding: '.25rem .5rem',
                  color: colors.white,
                  textAlign: 'center',
                  textDecoration: 'none',
                  textTransform: 'uppercase',
                  backgroundColor: colors.base,
                }}
              >
                {t('buttons.view.products')}
              </Link>
            )}
          </div>
        ))}
      </LookList>
    </Layout>
  )
}

export default Collection
